import React from 'react';
import ReactDOM from 'react-dom';

import {createTheme, ThemeProvider} from '@mui/material/styles';

import {Box, CircularProgress} from '@mui/material';

const App = React.lazy(() => import('./App'));

const theme = createTheme({
  components: {
    MuiTablePagination: {
      styleOverrides: {
        spacer: {
          flex: 'none'
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: '250px',
        },
        paper: {
          width: '250px',
          position: 'static',
          height: 'calc(100vh - 64px)'
        }
      },
    },
  },
  palette: {
    primary: {
      extraLight: '#90A9B7',
      light: '#006257',
      main: '#0b4db0',
      dark: '#006257',
      contrastText: '#fff',
    },
    secondary: {
      extraLight: '#90A9B7',
      light: '#1043A0',
      main: '#274478',
      dark: '#052868',
      contrastText: '#fff',
    },
    error: {
      extraLight: '#90A9B7',
      light: '#f73378',
      main: '#f50057',
      dark: '#ab003c',
      contrastText: '#fff',
    },
  },
});
theme.spacing(2);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.Suspense
      fallback={
        <Box p={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      }
    >
      <App />
    </React.Suspense>
  </ThemeProvider>,
  document.getElementById('root')
);
